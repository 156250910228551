import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import LinkComponent from '@shared/ui/navigation/Link';
import AvatarImage from '@shared/ui/display/AvatarImage';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import { ActivityType } from '@entities/current-user';
const LinkStyled = styled(LinkComponent)(() => ({
    fontSize: 15,
    fontWeight: 600,
    textDecorationLine: 'none',
}));
const ImageWrapper = styled.span(() => ({
    display: 'block',
    width: 18,
    height: 18,
    borderRadius: '50%',
    userSelect: 'none',
    '&': {
        fontSize: 10,
    },
}));
const LinkContainer = styled.span(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.s,
    alignItems: 'center',
}));
const ActivityInfoLink = ({ eventType, subjectId, subjectName }) => {
    const { navigate, routes } = useAppNavigation();
    const handleLinkClick = () => {
        let link;
        switch (eventType) {
            case ActivityType.ClosePortfolio:
            case ActivityType.CreatePortflio:
                break;
            case ActivityType.StartFollowing:
            case ActivityType.StopFollowing:
                link = routes.PROFILE;
                break;
            default:
                break;
        }
        if (link) {
            navigate(link, { params: { nickname: subjectId } });
        }
    };
    const LinkElement = useMemo(() => {
        switch (eventType) {
            case ActivityType.ClosePortfolio:
            case ActivityType.CreatePortflio:
                return (_jsxs(LinkContainer, { children: [_jsx(ImageWrapper, { children: _jsx(AvatarImage, { publicName: subjectName, size: 'activityImage' }) }), _jsx(LinkStyled, { onClick: handleLinkClick, children: subjectName })] }));
            case ActivityType.StartFollowing:
            case ActivityType.StopFollowing:
                return (_jsxs(LinkContainer, { children: [_jsx(ImageWrapper, { children: _jsx(AvatarImage, { publicName: subjectName, size: 'activityImage' }) }), _jsx(LinkStyled, { onClick: handleLinkClick, children: subjectName })] }));
            default:
                return null;
        }
    }, [eventType, subjectId]);
    return LinkElement;
};
export default ActivityInfoLink;
